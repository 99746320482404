import { Box, Button, ButtonProps, SxProps, Theme } from '@mui/material';
import React from 'react';
import { NextLinkComposed } from '@summer-health/ui/next-link-composed';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

type AnalyticsTrack = (
  event: string,
  options: {
    button_text: string;
    button_link?: string;
    button_variant: string;
    card_id?: string;
    page_section?: string;
  },
) => void;

export interface AnalyticsButtonProps extends ButtonProps {
  event?: string;
  cardId: string;
  to?: string;
  href?: string;
  pageSection?: string;
  children: React.ReactNode | string;
  component?: React.ElementType;
  target?: string;
  testId?: string;
  // optionally you can pass cta copy if its an icon button
  ctaText?: string;
  sx?: SxProps<Theme>;
}

type LinkProps = Pick<
  AnalyticsButtonProps,
  'to' | 'href' | 'target' | 'component'
>;

const getLinkProps = ({
  to,
  href,
  component,
  target,
}: LinkProps): LinkProps => {
  if (to && !component) {
    return {
      to,
      component: NextLinkComposed,
    };
  }

  if (to && component) {
    return {
      to,
      component,
    };
  }

  if (href) {
    return {
      href,
      component: 'a',
      target,
    };
  }

  if (component) {
    return {
      component,
    };
  }

  return {};
};

export const AnalyticsButton = ({
  event = 'Button Clicked',
  to,
  cardId,
  children,
  color = 'primary',
  ctaText,
  component,
  disabled,
  endIcon,
  fullWidth,
  href,
  onClick: onButtonClick,
  pageSection,
  target,
  testId,
  startIcon,
  sx,
  variant = 'contained',
  ...other
}: AnalyticsButtonProps): JSX.Element => {
  const extraProps = getLinkProps({ to, href, component, target });
  const onClick = (): void => {
    const track = window.analytics?.track as AnalyticsTrack;
    if (track) {
      const copy = typeof children === 'string' ? children : ctaText;

      if (!copy) {
        // eslint-disable-next-line no-console
        console.warn('Warning! No button copy defined for analytics');
      }

      track(event, {
        button_text: copy ?? 'Warning! No button text defined',
        button_link: to,
        button_variant: color,
        card_id: cardId,
        page_section: pageSection,
      });
    }
  };

  return (
    <Box
      {...(!disabled && { onClick })}
      sx={{ ...sx, ...(fullWidth && { width: '100%' }) }}
    >
      <Button
        color={color}
        variant={variant}
        aria-disabled={disabled}
        disabled={disabled}
        onClick={onButtonClick}
        startIcon={startIcon}
        endIcon={endIcon}
        fullWidth={fullWidth}
        data-testid={testId ?? cardId}
        {...extraProps}
        {...other}
      >
        {children}
      </Button>
    </Box>
  );
};
