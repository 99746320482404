import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface FullScreenImageWithChildrenProps {
  imgSrc: string;
  children: React.ReactNode;
}

const ImageWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    width: '100%',
    height: '100vh',
    left: 0,
    top: 0,
  },
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: '600px',
    height: '700px',
    borderRadius: theme.spacing(2.5),
  },
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
}));

const FixedWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    bottom: '20px',
    left: 0,
  },
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

export const FullScreenImageWithChildren = ({
  imgSrc,
  children,
}: FullScreenImageWithChildrenProps): JSX.Element => {
  if (!imgSrc) {
    return (
      <ImageWrapper>
        <FixedWrapper>{children}</FixedWrapper>
      </ImageWrapper>
    );
  }

  return (
    <ImageWrapper
      sx={{
        backgroundImage: `url(${imgSrc})`,
      }}
    >
      <FixedWrapper>{children}</FixedWrapper>
    </ImageWrapper>
  );
};
