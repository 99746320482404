import * as React from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { styled } from '@mui/material/styles';

const Anchor = styled('a')({});

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<
      NextLinkProps,
      'href' | 'as' | 'passHref' | 'onMouseEnter' | 'onClick' | 'onTouchStart'
    > {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
  linkTarget?: string;
}

export const NextLinkComposed = React.forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>((props, ref) => {
  const {
    to,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    legacyBehavior = true,
    locale,
    linkTarget,
    onClick,
    ...other
  } = props;

  const nextLinkProps = onClick
    ? {
        legacyBehavior: true,
        passHref: true,
      }
    : { target: linkTarget };

  const clickProps = onClick
    ? {
        onClick: async (
          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ): Promise<void> => {
          if (!onClick) {
            return;
          }

          e.preventDefault();
          await onClick(e);

          const isExternal =
            typeof to === 'string' &&
            (to.indexOf('http') === 0 ||
              to.indexOf('mailto:') === 0 ||
              to.indexOf('sms:') === 0);

          if (isExternal) {
            if (linkTarget === '_self') {
              window.location.href = to;
            } else {
              window.open(to, '_blank');
            }
          } else {
            window.history.pushState(window.history.state, '', to as string);
          }
        },
      }
    : { target: linkTarget };

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
      legacyBehavior={legacyBehavior}
      {...nextLinkProps}
    >
      <Anchor ref={ref} {...other} {...clickProps} />
    </NextLink>
  );
});
NextLinkComposed.displayName = 'NextLinkComposed';
