/**
 * This is a port of https://github.com/contentful/rich-text/tree/master/packages/rich-text-html-renderer
 * Which converts contentfuls rich text editor content to an html string. Since we use MUI for styling this
 * attempts to convert the contentful rich text to MUI components.
 */
import {
  Document,
  Mark,
  Text,
  BLOCKS,
  MARKS,
  INLINES,
  Block,
  Inline,
  helpers,
} from '@contentful/rich-text-types';
import { styled } from '@mui/material/styles';
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

export type CommonNode = Text | Block | Inline;

export type Element = JSX.Element | string;

export interface Next {
  (nodes: CommonNode[]): Element[];
}

export interface NodeRenderer {
  (node: Block | Inline, key: string, next: Next): JSX.Element;
}

export interface RenderNode {
  [k: string]: NodeRenderer;
}

export interface RenderMark {
  [k: string]: (text: Element, key: string) => JSX.Element;
}

export interface Options {
  /**
   * Node renderers
   */
  renderNode?: RenderNode;
  /**
   * Mark renderers
   */
  renderMark?: RenderMark;
}

const StyledListItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  display: 'list-item',
  listStyle: 'disc',
  padding: 0,
  width: `calc(100% - ${theme.spacing(2)})`,
  marginLeft: theme.spacing(2),
}));

const attributeValue = (value: string): string =>
  `"${value.replace(/"/g, '&quot;')}"`;

const defaultNodeRenderers: RenderNode = {
  [BLOCKS.PARAGRAPH]: (node, key, next) => (
    <Typography key={`p-${key}`} component="p">
      {next(node.content)}
    </Typography>
  ),
  [BLOCKS.HEADING_1]: (node, key, next) => (
    <Typography key={`h1-${key}`} variant="h1">
      {next(node.content)}
    </Typography>
  ),
  [BLOCKS.HEADING_2]: (node, key, next) => (
    <Typography key={`h2-${key}`} variant="h2">
      {next(node.content)}
    </Typography>
  ),
  [BLOCKS.HEADING_3]: (node, key, next) => (
    <Typography key={`h3-${key}`} variant="h3">
      {next(node.content)}
    </Typography>
  ),
  [BLOCKS.HEADING_4]: (node, key, next) => (
    <Typography key={`h4-${key}`} variant="h4">
      {next(node.content)}
    </Typography>
  ),
  [BLOCKS.HEADING_5]: (node, key, next) => (
    <Typography key={`h5-${key}`} variant="h5">
      {next(node.content)}
    </Typography>
  ),
  [BLOCKS.HEADING_6]: (node, key, next) => (
    <Typography key={`h6-${key}`} variant="h6">
      {next(node.content)}
    </Typography>
  ),
  [BLOCKS.EMBEDDED_ENTRY]: (node, key, next) => (
    <Box key={`embed-entry-${key}`} component="div">
      ${next(node.content)}
    </Box>
  ),
  [BLOCKS.EMBEDDED_RESOURCE]: (node, key, next) => (
    <Box key={`embed-resource-${key}`} component="div">
      ${next(node.content)}
    </Box>
  ),
  [BLOCKS.UL_LIST]: (node, key, next) => (
    <List key={`list-${key}`}>{next(node.content)}</List>
  ),
  [BLOCKS.OL_LIST]: (node, key, next) => (
    <List key={`list-${key}`}>{next(node.content)}</List>
  ),
  [BLOCKS.LIST_ITEM]: (node, key, next) => (
    <StyledListItem key={`list-item-${key}`}>
      <ListItemText primary={next(node.content)} />
    </StyledListItem>
  ),
  [BLOCKS.QUOTE]: (node, key, next) => (
    <Typography key={key} component="blockquote">
      {next(node.content)}
    </Typography>
  ),
  [BLOCKS.HR]: () => <Divider variant="standard" />,
  [BLOCKS.TABLE]: (node, key, next) => (
    <TableContainer key={key} component={Paper}>
      <Table sx={{ minWidth: 650 }}>${next(node.content)}</Table>
    </TableContainer>
  ),
  [BLOCKS.TABLE_ROW]: (node, key, next) => (
    <TableRow key={key}>{next(node.content)}</TableRow>
  ),
  // TODO: Figure out how to handle table header cells.
  [BLOCKS.TABLE_HEADER_CELL]: (node, key, next) => (
    <TableCell key={key}>${next(node.content)}</TableCell>
  ),
  [BLOCKS.TABLE_CELL]: (node, key, next) => (
    <TableCell key={key}>${next(node.content)}</TableCell>
  ),
  [INLINES.HYPERLINK]: (node, key, next) => {
    const href = typeof node.data.uri === 'string' ? node.data.uri : '';
    return (
      <Link key={key} href={attributeValue(href)}>
        {next(node.content)}
      </Link>
    );
  },
};

const defaultMarkRenderers: RenderMark = {
  [MARKS.BOLD]: (text, key) => <b key={`b-${key}`}>{text}</b>,
  [MARKS.ITALIC]: (text, key) => <i key={`i-${key}`}>{text}</i>,
  [MARKS.UNDERLINE]: (text, key) => <u key={`u-${key}`}>{text}</u>,
  [MARKS.CODE]: (text, key) => <code key={`code-${key}`}>{text}</code>,
  [MARKS.SUPERSCRIPT]: (text, key) => <sup key={`sup-${key}`}>{text}</sup>,
  [MARKS.SUBSCRIPT]: (text, key) => <sub key={`sub-${key}`}>{text}</sub>,
};

function nodeListToMui(
  nodes: CommonNode[],
  { renderNode, renderMark }: Options,
): Element[] {
  return nodes.map<Element>((node, i) =>
    // eslint-disable-next-line no-use-before-define
    nodeToMui(node, `nodeKey-${i}`, { renderNode, renderMark }),
  );
}

function nodeToMui(
  node: CommonNode,
  key: string,
  { renderNode, renderMark }: Options,
): Element {
  if (helpers.isText(node)) {
    const nodeValue = node.value;
    if (node.marks.length > 0) {
      return node.marks.reduce((value: Element, mark: Mark): Element => {
        if (renderMark && !renderMark[mark.type]) {
          return value;
        }
        return renderMark ? renderMark[mark.type](value, key) : value;
      }, nodeValue as Element);
    }

    return nodeValue;
  }
  const nextNode: Next = (nodes) =>
    nodeListToMui(nodes, { renderMark, renderNode });
  if (!node.nodeType || (renderNode && !renderNode[node.nodeType])) {
    // TODO: Figure what to return when passed an unrecognized node.
    return <></>;
  }
  return renderNode ? renderNode[node.nodeType](node, key, nextNode) : <></>;
}

/**
 * Serialize a Contentful Rich Text `document` to an html string.
 */
export function documentToMui(
  richTextDocument: Document,
  options: Partial<Options> = {},
): Element[] {
  if (!richTextDocument || !richTextDocument.content) {
    return [<></>];
  }

  return nodeListToMui(richTextDocument.content, {
    renderNode: {
      ...defaultNodeRenderers,
      ...options.renderNode,
    },
    renderMark: {
      ...defaultMarkRenderers,
      ...options.renderMark,
    },
  });
}
