import { Grid } from '@mui/material';
import { Logo } from '@summer-health/ui/logo';

export const SignupHeader = (): JSX.Element => (
  <Grid
    container
    padding={1.5}
    display="flex"
    justifyContent={{ xs: 'center', md: 'flex-start' }}
  >
    <Logo size="lg" />
  </Grid>
);
