import { Fade, Slide } from '@mui/material';
import { useRouteChangeVisibility } from '@summer-health/ui/care-animations';
import { FullScreenImageWithChildren } from './fullscreen-image-with-children';
import { InterstitialCard, InterstitialCardProps } from './interstitial-card';

export type InterstitialPageProps = {
  isLoading: boolean;
} & InterstitialCardProps;

export const InterstitialPage = ({
  content,
  isLoading,
  variables,
  navigateUrlOverride,
}: InterstitialPageProps): JSX.Element => {
  const { visible } = useRouteChangeVisibility();

  if (isLoading) {
    return <></>;
  }

  const { splashMedia } = content;

  return (
    <Fade in={visible} timeout={1000}>
      <div>
        <FullScreenImageWithChildren imgSrc={splashMedia?.url}>
          <Slide direction="up" in={visible} timeout={1000}>
            <div>
              <InterstitialCard
                content={content}
                variables={variables}
                navigateUrlOverride={navigateUrlOverride}
              />
            </div>
          </Slide>
        </FullScreenImageWithChildren>
      </div>
    </Fade>
  );
};
