import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useRouteChangeVisibility = (): {
  visible: boolean;
} => {
  const [visible, setVisible] = useState(false);
  const router = useRouter();

  const transitionIn = (): void => {
    setVisible(true);
  };

  const transitionOut = (): void => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(true);
    router.events.on('routeChangeStart', transitionOut);
    router.events.on('routeChangeError', transitionIn);

    return (): void => {
      router.events.off('routeChangeStart', transitionOut);
      router.events.off('routeChangeError', transitionIn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { visible };
};
