export const ContentfulInterstitialFragment = `
    name
    header
    body {
      json
    }
    buttonsCollection(limit: 10) {
      items {
        sys {
          id
        }
        displayText
        url
        color
      }
    }
    splashMedia {
      description
      contentType
      url
    }
    membershipTier {
      tier
    }
`;
