import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const FixedWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: `var(--mui-sunrise-light-info-main)`,
  color: `var(--mui-sunrise-light-info-onMain)`,
  width: '100%',
  textAlign: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 100,
  },
}));

export type PromotionBannerProps = {
  promotionCode?: string;
};

export const PromotionBanner = ({
  promotionCode,
}: PromotionBannerProps): JSX.Element => {
  if (!promotionCode) {
    return <></>;
  }

  return (
    <FixedWrapper>Promo code {promotionCode} has been applied</FixedWrapper>
  );
};
