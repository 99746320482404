import { Document } from '@contentful/rich-text-types';
import { Box, SxProps, Theme } from '@mui/material';
import { Options, documentToMui } from './rich-text-to-mui';

export const ContentfulRichText = ({
  content,
  sx,
  key,
  options,
}: {
  content: Document;
  sx?: SxProps<Theme>;
  key?: string;
  options?: Partial<Options>;
}): JSX.Element => (
  <Box component="div" sx={sx} key={key ?? 'rich-text-component'}>
    {documentToMui(content, options)}
  </Box>
);
